import React from 'react';
import NotFound from '../components/NotFound';
import MetaTags from '../components/Seo/MetaTags';
import { useTranslation } from 'react-i18next';

const IndexPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <MetaTags title={t('NotFound.seo.title')} />
      <NotFound />
    </>
  );
};

export default IndexPage;
